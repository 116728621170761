import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
export default function useMedicineList() {

  const searchQuery = ref('');
  const selected_group = ref('');
  const selected_status = ref('');
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const Group_Total = ref(0);
  const Type_Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const patient_id = ref(router.currentRoute.params.id);
  const tableColumns = [

    { field: 'created_at', label: 'วันที่', sortable: false},
      { field: 'code', label: 'รหัส', sortable: false},
        { field: 'checklists', label: 'รายการตรวจ', sortable: false },
        { field: 'description', label: 'ลายละเอียด', sortable: false },
        { field: 'action', label: 'ตัวเลือก', sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const refetchData = () => {
      refListTable.value.refresh()
  }

    watch([currentPage, pageLength, searchQuery, selected_status,patient_id], () => {
      fetchData();
    })

    const fetchData = async () =>{
      const offset = pageLength.value*(currentPage.value-1);
      Data.value  = [];
      console.log('patient_id.value',patient_id.value);
      await store
            .dispatch('app-opd/fetchOpdCardData', {
              limit: pageLength.value,
              offset:offset,
              patient_id:patient_id.value,
              keywords: searchQuery.value,
             // branch_id:branch_id,
              order_by:'id DESC'
          
            })
            .then(response => {
              Data.value = response.data.data;
              console.log(Data.value);
              const total = response.data.total?response.data.total: Data.value.length;
              Group_Total.value = total;
            })
            .catch((error) => {
              console.log('error',error);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching opdcard list",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
    }

    fetchData();


    const deleteData = (id) =>{
      //fetchDeleteProperty
      var data = {id:id}
      var res = store.dispatch('app-opd/deleteData',data)

      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }

    
    const deleteOpdCardData = (id) => {
      //fetchDeleteProperty
      var data = {id:id}
      var res = store.dispatch('app-opd/deleteOpdCardData',data)

      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }

    const resolveUserStatusVariant = status => {
       
      if (status) return 'success'
      if (!status) return 'secondary'
      return 'primary'
    }
    const resolveUserStatusnameVariant = status => {
    
        if (status) return 'Active'
        if (!status) return 'Inactive'
        return 'primary'
    }

    return {
        tableColumns,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_status,
        patient_id,
        currentPage,
        refListTable,
        deleteData,
        deleteOpdCardData,
        fetchData,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant
    }
}