<template
>
    <div>
    <b-row>
        <b-col lg="4">
            <b-card no-body>
                <patients-card />
            </b-card>
        </b-col>
        <b-col lg="8">
            <queue-form 
            v-if="isQueueFormSidebarActive"
            :is-queue-form-sidebar-active.sync="isQueueFormSidebarActive"
            :is-opd-detail-sidebar-active.sync="isOpdDetailSidebarActive"
            :main_opdcard_id.sync="main_opdcard_id"
            @refetch-data="fetchData"
            />
            <opd-form
            v-if="isOpdFormSidebarActive"
            :is-queue-form-sidebar-active.sync="isQueueFormSidebarActive"
            :is-opd-form-sidebar-active.sync="isOpdFormSidebarActive"
            :is-opd-detail-sidebar-active.sync="isOpdDetailSidebarActive"
            :opdData.sync="opdData"
            :main_opdcard_id.sync="main_opdcard_id"
            @refetch-data="fetchData" 
            />
        <transition name="fade">
        <b-card no-body        
        v-if="isOpdDetailSidebarActive"
        >
            <div class="m-2">
            <b-row>
                <b-col
                class="text-dark"
                >
                  <div class="mt-2 ml-2">
                    <h2 class="text-dark">รายการตรวจเลขที่</h2>
                  </div>
                </b-col>
                <b-col
                >
                    <b-form-group
                        label="ค้นหา"
                        label-for="search"
                        >
                        <b-input-group class="input-group-merge">
                            <b-form-input name="search" v-model="searchQuery" />
                            <b-input-group-append is-text>
                                <feather-icon icon="SearchIcon" color="#58ADEB" />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    
                </b-col>
            </b-row>
        </div>
        <div class="m-2">
            
            <vue-good-table
            :columns="tableColumns"
            :rows="Data"
            ref="refListTable"

            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a opded table, add a checkbox in the header row to check/uncheck the entire opd
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >
            
            <span 
            v-if="props.column.field == 'is_active'"
            class="text-nowrap"
            >
                <b-badge
                    pill
                    :variant="`light-${resolveUserStatusVariant(props.row.is_active)}`"
                    class="text-capitalize"
                >
                    {{ resolveUserStatusnameVariant(props.row.is_active) }} 
                </b-badge>
            </span>
            
            <span 
            v-else-if="props.column.field == 'description'"
            class="text-nowrap"
            >
                <small v-if="props.row.description">{{props.row.description}}</small>
            </span>

            <span 
            v-else-if="props.column.field == 'checklists'"
            class="text-nowrap"
            >
            <ul v-if="props.row.checklist_title"
              v-for="item,index in props.row.checklist_title">
            <li v-if="index<3"><h6 class="text-secondary">{{item}}</h6></li>
            </ul>
            <b-collapse :id="`${props.row.id}`" v-if="props.row.checklist_title.length > 3">
              <ul v-for="item,index in props.row.checklist_title">
              <li v-if="index>=3" :id="`${props.row.id}`"><h6 class="text-secondary">{{item}}</h6></li>
              </ul>
            </b-collapse>
            <b-button 
            v-if="props.row.checklist_title.length > 3"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-light"
            class="ml-2 border-light text-info btn btn-sm" 
            @click.Prevent
            v-b-toggle="`${props.row.id}`"
            >เพิ่มเติม
            </b-button>
            
            </span>

            <span
            v-else
            class="text-nowrap"
            >
            {{props.formattedRow[props.column.field]}}
            <span
                v-if="props.column.field === 'action'"
                class="text-nowrap"
                >

                <b-button
                    :disabled="loading"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-success"
                    class="btn-icon rounded-circle"
                    @click="addOpd(props.row)"
                >
                  <feather-icon icon="GitMergeIcon"/>
                </b-button>


                <b-button
                    :disabled="loading"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="updateData(props.row)"

                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                
                <b-button
                    :disabled="loading"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id,props.row.opd_id,props.row.code)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>

            </span>

            <span 
              v-if="props.row.sub_opdcard"
              v-for="item in props.row.sub_opdcard"
              class="text-nowrap"
              >
                <div class="pt-2">
                  <span v-if="props.column.field == 'created_at'"
                  class="text-success"
                  >
                    <feather-icon icon="GitMergeIcon"/>
                  </span>
                   
                   {{ item[props.column.field]}}
                  <span
                  v-if="props.column.field === 'action'"
                  class="text-nowrap"
                  >
                  <b-button
                      :disabled="loading"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="flat-info"
                      class="btn-icon rounded-circle"
                      @click="updateData(item)"

                  >
                      <feather-icon icon="Edit3Icon" />
                  </b-button>
                  
                  <b-button
                      :disabled="loading"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                      v-on:click="deleteconfirm(item.id,item.opd_id,item.code)"
                  >
                      <feather-icon icon="Trash2Icon" />
                  </b-button>
                  </span>
                </div>
              </span>
            </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="Total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
            
        </div>
    <!-- Form Actions -->
    <div class="d-inline-block m-2">
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="transpant"
        class="mr-2 border-info text-info float-left"
        type="button"
        :disabled="loading"
        >
        Option <small>เพิ่ม</small>
        </b-button>
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="transpant"
        class="mr-2 border-success text-success float-right"
        type="button"
        @click="addOpd"
        :disabled="loading"
        >
        เพิ่มรายการตรวจ
        </b-button>
    </div>
    </b-card>
    </transition>
    </b-col>
</b-row>
</div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import useOpdList from './useOpdList'
import PatientsCard from '../../patients/patients-detail/PatientsCard.vue'
import opdStoreModule from '../opdStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import OpdForm from './OpdForm.vue'
import QueueForm from '../../queue/queue-form/QueueForm.vue'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { formatDateTh,getCommaDecimal } from '@core/utils/filter'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,
        VueGoodTable,
        vSelect,
        OpdForm,
        QueueForm,
        PatientsCard,

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    data(){
      return {
        formatDateTh
      }
    },
    setup(props, { emit }) {

        const HeaderText = ref('')
        const loading = ref(false);
        const isOpdDetailSidebarActive = ref(true)
        const isOpdFormSidebarActive = ref(false)
        const isQueueFormSidebarActive = ref(false)

        const opdData = ref({})
        const main_opdcard_id = ref(0)
        const toast = useToast()
        const options_status = ref([
            { value:'1',text:'Active' },
            { value:'0',text:'Inactive' },
        ]);
        const PATIENTS_STORE_MODULE_NAME = 'app-opd';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, opdStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            tableColumns,
            fetchData,
            deleteData,
            deleteOpdCardData,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            selected_status,
            patient_id,
            currentPage,
            refListTable,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
        } = useOpdList();

        const addOpd = async(data)=>{
          loading.value = true
        opdData.value = {};
        HeaderText.value = 'เพิ่มopd';

        store.dispatch('app-opd/fetchPatientData', {id:patient_id.value})
          .then((response) => {
            // const dates = new Date();
            opdData.value.first_name = response.data.data[0].first_name;
            opdData.value.last_name = response.data.data[0].last_name;
            opdData.value.gender = response.data.data[0].gender;
            opdData.value.age = response.data.data[0].age;
            opdData.value.career = response.data.data[0].career;
            opdData.value.education = response.data.data[0].education;
            opdData.value.birthdate = response.data.data[0].birthdate;
            opdData.value.know_from = response.data.data[0].know_from;
            opdData.value.NationalIDNumber = response.data.data[0].NationalIDNumber;
            opdData.value.sign_recipient = response.data.data[0].first_name+' '+response.data.data[0].last_name;
            opdData.value.sign_date = new Date().toISOString().slice(0,10);

            loading.value = false;
            isOpdFormSidebarActive.value = true;
            isOpdDetailSidebarActive.value = false;
          }).catch(()=>{
            loading.value = false;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Fetch Information",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })

        }

        const updateData = async (data)=>{
          loading.value = true;

            await store.dispatch('app-opd/fetchOpdCardData', {id:data.id})
          .then((res) => {
            opdData.value = res.data.data[0];
            HeaderText.value = 'แก้ไขopd';


            store.dispatch('app-opd/fetchPatientData', {id:patient_id.value})
          .then((response) => {
            // const dates = new Date();
            opdData.value.first_name = response.data.data[0].first_name;
            opdData.value.last_name = response.data.data[0].last_name;
            opdData.value.gender = response.data.data[0].gender;
            opdData.value.age = response.data.data[0].age;
            opdData.value.career = response.data.data[0].career;
            opdData.value.education = response.data.data[0].education;
            opdData.value.birthdate = response.data.data[0].birthdate;
            opdData.value.know_from = response.data.data[0].know_from;
            opdData.value.NationalIDNumber = response.data.data[0].NationalIDNumber;
            opdData.value.sign_recipient = response.data.data[0].first_name+' '+response.data.data[0].last_name;
            opdData.value.sign_date = new Date().toISOString().slice(0,10);

            loading.value = true;
            isOpdFormSidebarActive.value = true;
            isOpdDetailSidebarActive.value = false;
          }).catch(()=>{
            loading.value = false;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Fetch Information",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
            loading.value = false;
            main_opdcard_id.value = data.id;
            toast({
            component: ToastificationContent,
            props: {
              title: "Save Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          }).catch(()=>{
            loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        }

        return {
            options_status,
            tableColumns,
            fetchData,
            deleteData,
            deleteOpdCardData,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            selected_status,
            currentPage,
            refListTable,
            patient_id,
            opdData,
            main_opdcard_id,
            addOpd,
            updateData,
            isOpdFormSidebarActive,
            isQueueFormSidebarActive,
            isOpdDetailSidebarActive,
            HeaderText,
            loading
        }

        
    },methods:{
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        deleteconfirm(opdcard_id,opd_id,code){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete opdcard: '+code, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            this.deleteOpdCardData(opdcard_id) //delete opd card
            this.deleteData(opd_id) //delete opd
          }
        })

        },
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>